<ng-container [ngSwitch]="level">
  <ng-container *ngIf="formGroup && controlName">
    <ng-container [formGroup]="formGroup">
      <h1 [formControlName]="controlName" [libAdaptEditable]="editable" [ngClass]="ngClass" *ngSwitchCase="'h1'">
        <ng-container [ngTemplateOutlet]="content"></ng-container>
      </h1>
      <h2 [formControlName]="controlName" [libAdaptEditable]="editable" [ngClass]="ngClass" *ngSwitchCase="'h2'">
        <ng-container [ngTemplateOutlet]="content"></ng-container>
      </h2>
      <h3 [formControlName]="controlName" [libAdaptEditable]="editable" [ngClass]="ngClass" *ngSwitchCase="'h3'">
        <ng-container [ngTemplateOutlet]="content"></ng-container>
      </h3>
      <h4 [formControlName]="controlName" [libAdaptEditable]="editable" [ngClass]="ngClass" *ngSwitchCase="'h4'">
        <ng-container [ngTemplateOutlet]="content"></ng-container>
      </h4>
      <h5 [formControlName]="controlName" [libAdaptEditable]="editable" [ngClass]="ngClass" *ngSwitchCase="'h5'">
        <ng-container [ngTemplateOutlet]="content"></ng-container>
      </h5>
      <h6 [formControlName]="controlName" [libAdaptEditable]="editable" [ngClass]="ngClass" *ngSwitchCase="'h6'">
        <ng-container [ngTemplateOutlet]="content"></ng-container>
      </h6>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="!formGroup || !controlName">
    <h1 [ngClass]="ngClass" *ngSwitchCase="'h1'"><ng-container [ngTemplateOutlet]="content"></ng-container></h1>
    <h2 [ngClass]="ngClass" *ngSwitchCase="'h2'"><ng-container [ngTemplateOutlet]="content"></ng-container></h2>
    <h3 [ngClass]="ngClass" *ngSwitchCase="'h3'"><ng-container [ngTemplateOutlet]="content"></ng-container></h3>
    <h4 [ngClass]="ngClass" *ngSwitchCase="'h4'"><ng-container [ngTemplateOutlet]="content"></ng-container></h4>
    <h5 [ngClass]="ngClass" *ngSwitchCase="'h5'"><ng-container [ngTemplateOutlet]="content"></ng-container></h5>
    <h6 [ngClass]="ngClass" *ngSwitchCase="'h6'"><ng-container [ngTemplateOutlet]="content"></ng-container></h6>
  </ng-container>
</ng-container>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>
