import { Component } from '@angular/core';
import { SettingsService } from '@adapt/adapt-shared-component-lib';

@Component({
  selector: 'adapt-viewer-footer',
  templateUrl: './viewer-footer.component.html',
  styleUrls: ['./viewer-footer.component.scss'],
})
export class ViewerFooterComponent {
  public year = new Date().getFullYear();

  public $settings = this.settings.getSettingsObservable();

  constructor(public settings: SettingsService) {}
}
