<section class="usa-banner" aria-label="ADAPT banner">
  <div class="usa-accordion">
    <header class="usa-banner__header">
      <div class="usa-banner__inner">
        <div class="grid-col-auto">
          <img
            aria-hidden="true"
            class="usa-banner__header-flag"
            src="assets/shared/logos/adapt-logo@mini.svg"
            alt="ADAPT logo" />
        </div>
        <div class="grid-col-fill tablet:grid-col-auto" aria-hidden="true">
          <p class="usa-banner__header-text display-none tablet:display-flex">
            You are experiencing the ADAPT {{ environment.envLabel }} environment. The data contained within are for
            testing purposes only.
          </p>
          <p class="usa-banner__header-text display-flex tablet:display-none">
            {{ environment.envLabel }} environment, testing purposes only.
          </p>
          <!-- <p class="usa-banner__header-action">Here’s how you know</p> -->
        </div>
        <!-- <button
        type="button"
        class="usa-accordion__button usa-banner__button"
        aria-expanded="false"
        aria-controls="gov-banner-default-default"
      >
        <span class="usa-banner__button-text">Here’s how you know</span>
      </button> -->
      </div>
    </header>
    <!-- <div
    class="usa-banner__content usa-accordion__content"
    id="gov-banner-default-default"
  >
    <div class="grid-row grid-gap-lg">
      <div class="usa-banner__guidance tablet:grid-col-6">
        <i class="fas fa-shield-halved font-xl margin-right-1"></i>
        <div class="usa-media-block__body">
          <p>
            <strong>Because we said so</strong><br />We should definitely update this text before launch.
          </p>
        </div>
      </div>
    </div>
  </div> -->
  </div>
</section>
