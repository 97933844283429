import { CUSTOM_ELEMENTS_SCHEMA, Inject, makeEnvironmentProviders, NgModule, PLATFORM_ID } from '@angular/core';

import { ViewerSidebarComponent } from './components/sidebar/sidebar.component';
import { LibModule } from '@adapt/adapt-shared-component-lib';
import { ViewerFooterComponent } from './components/footer/viewer-footer.component';
import { TopBannerComponent } from './components/banner/top-banner.component';
import { AsyncPipe, CommonModule } from '@angular/common';
import { HomeComponent } from './pages/home/home.component';
import { BrandingHeaderComponent } from './components/branding-header/branding-header.component';
import { WeissAccessibilityCenterModule } from 'weiss-accessibility-center';
import { ResourcesComponent } from './pages/resources/resources.component';
import { RouterModule } from '@angular/router';
import { environment } from '../environments/environment';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ReportComponent } from './pages/report/report.component';
import { ReportsComponent } from './pages/reports/reports.component';


@NgModule({
  declarations: [
    HomeComponent,
    ViewerSidebarComponent,
    BrandingHeaderComponent,
    TopBannerComponent,
    ViewerFooterComponent,
    ResourcesComponent,
    ReportComponent,
    ReportsComponent,
  ],
  exports: [
    HomeComponent,
    ViewerSidebarComponent,
    BrandingHeaderComponent,
    ViewerFooterComponent,
    TopBannerComponent,
    WeissAccessibilityCenterModule,
  ],
  imports: [CommonModule, WeissAccessibilityCenterModule,  LibModule, ReactiveFormsModule, FormsModule, RouterModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
