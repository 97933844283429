import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import {
  HomePageContentText,
  ResourcePageContentText,
  SharedContentText,
  ViewerContentText,
} from '@adapt-apps/adapt-viewer/src/app/models/content-text.model';
import { environment } from '@adapt-apps/adapt-viewer/src/environments/environment';
import { ContentService } from '@adapt/adapt-shared-component-lib';

@Injectable({
  providedIn: 'root',
})
export class ViewerPagesContentService {
  viewerContentSubject: ReplaySubject<ViewerContentText> = new ReplaySubject<ViewerContentText>(1);
  viewerContent: ViewerContentText;

  constructor(public contentService: ContentService) {}

  // subscribe to the timer service and get the time left before the timer expired
  getViewerContentListener(): Observable<ViewerContentText> {
    return this.viewerContentSubject.asObservable();
  }

  loadContent() {

    //console.log('Inside ViewerPagesContentService.loadContent(), environment.defaultContent: ', environment.defaultContent);
    const viewerContent = sessionStorage.getItem('viewer_content');

    if (!viewerContent) {
      this.contentService.loadContent(environment.defaultContent).subscribe((response: ViewerContentText) => {
        //console.log('response: ', response);

        if (response?.pages) {
          this.updateContent(response);
        }
      });
    } else {
      this.updateContent(JSON.parse(viewerContent));
    }
  }

  updateContent(viewerContent: ViewerContentText) {
    this.viewerContent = viewerContent;

    sessionStorage.setItem('viewer_content', JSON.stringify(viewerContent));

    //console.log('updateContent: ', viewerContent);
    this.viewerContentSubject.next(viewerContent);
  }

  getPageContent(pageName: string): ResourcePageContentText | HomePageContentText | SharedContentText | null {
    //const viewerContent = this.viewerContentSubject.getValue();

    //console.log('getPageContent, pageName: ', pageName);

    if (this.viewerContent?.pages) {
      if (pageName === 'shared') {
        return this.viewerContent?.shared!;
      } else if (pageName === 'resources') {
        return this.viewerContent?.pages[pageName] as ResourcePageContentText;
      } else if (pageName === 'home') {
        return this.viewerContent?.pages[pageName] as HomePageContentText;
      }
    }

    return null;
  }
}
