import { environment } from '../../environments/environment';
import { IReport, Response, ShareReport, ViewerTemplate } from '@adapt/types';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, map, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AdaptDataService {
  private _reports = new ReplaySubject<IReport[]>();
  private $reports = this._reports.asObservable();

  constructor(private http: HttpClient) {
    this.getReports().subscribe((reports) => {
      this._reports.next(reports);
    });
  }

  public getReports() {
    return this.http.get<Response<IReport[]>>(`${environment.API_URL}/reports`).pipe(map((resp) => resp.data));
  }

  public getReport(slug: string) {
    return this.http.get<Response<IReport>>(`${environment.API_URL}/reports/${slug}`).pipe(map((resp) => resp.data));
  }

  public getData(slug: string, filters: Record<string, any>) {
    return this.http
      .post<Response<ViewerTemplate>>(`${environment.API_URL}/reports/${slug}/data`, filters)
      .pipe(map((resp) => resp.data));
  }

  public shareReport(reportSlug: string, filters: Record<string, any>, tabIndex: number) {
    return this.http
      .post<Response<string>>(`${environment.API_URL}/reports/share`, { reportSlug, filters, tabIndex })
      .pipe(map((result) => result.data));
  }

  public loadSharedReport(shareSlug: string) {
    return this.http
      .get<Response<ShareReport>>(`${environment.API_URL}/reports/share/${shareSlug}`)
      .pipe(map((result) => result.data));
  }

  public get reports() {
    return this.$reports;
  }
}
