import { ApplicationConfig, makeEnvironmentProviders } from '@angular/core';
import { provideRouter, withEnabledBlockingInitialNavigation } from '@angular/router';
import { appRoutes } from './app.routes';
import { provideHttpClient, withFetch, withInterceptors } from '@angular/common/http';
import { provideClientHydration } from '@angular/platform-browser';
//import { provideContentServiceConfig } from '@adapt/adapt-shared-component-lib';
//import { environment } from '../environments/environment';

export const appConfig: ApplicationConfig = {
  providers: [
    //provideContentServiceConfig(environment.defaultContent),
    provideClientHydration(),
    provideRouter(appRoutes, withEnabledBlockingInitialNavigation()),
    provideHttpClient(withFetch()),
  ],
};
