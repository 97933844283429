import { afterNextRender, afterRender, AfterViewInit, Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LibModule } from '@adapt/adapt-shared-component-lib';
import { AppModule } from './app.module';
import { ViewerPagesContentService } from './services/content/viewer-pages-content.service';

@Component({
  standalone: true,
  imports: [RouterModule, LibModule, AppModule],
  selector: 'adapt-viewer-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'adapt-viewer';


  constructor(private content: ViewerPagesContentService) {
    this.content.loadContent();
    afterRender(() => {
      require('uswds');
    })
  }


}
