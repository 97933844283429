<!-- {page: contentResult?.pages?.resources, shared: contentResult.shared } as ctn -->
<ng-container *ngIf="resourcePageContent">
  <section class="resources-page-container grid-container gap-4 display-flex flex-column">
    <section
      [attr.aria-label]="resourcePageContent.heading.sectionLabel"
      role="region"
      class="resources-page-banner width-full display-flex gap-2 flex-column">
      <div class="usa-prose">
        <h1 class="margin-0" i18n>{{ resourcePageContent.heading.header }}</h1>
        <p class="margin-0 measure-4" i18n>
          {{ resourcePageContent.heading.description }}
        </p>
      </div>
    </section>

    <lib-adapt-secondary-navigation>
      <lib-adapt-secondary-navigation-item
        *ngFor="let item of resourcePageContent.secondaryNavigation.links"
        [queryParams]="item.queryParams"
        [name]="item.name"></lib-adapt-secondary-navigation-item>
    </lib-adapt-secondary-navigation>

    <ng-container [ngSwitch]="navigation | async">
      <ng-container *ngSwitchCase="'useful-links'">
        <section role="region" [attr.aria-label]="resourcePageContent.usefulLinks.headerSectionLabel" class="usa-prose">
          <h2>{{ resourcePageContent.usefulLinks.header }}</h2>
          <p>{{ resourcePageContent.usefulLinks.description }}</p>
        </section>

        <section
          role="region"
          class="grid-row grid-gap-sm"
          [attr.aria-label]="resourcePageContent.usefulLinks.listSectionLabel">
          <div
            *ngFor="let link of resourcePageContent.usefulLinks.links | fieldLengthSort : 'description'"
            class="useful-link-wrapper tablet:grid-col-6">
            <div
              class="bg-white padding-2 desktop:padding-3 margin-1 gap-1 border-2px display-flex flex-column border-base-lighter tablet:radius-lg">
              <div class="usa-prose">
                <h3>
                  <a
                    class="usa-link display-flex flex-align-center gap-1"
                    target="_blank"
                    [attr.aria-label]="link.name + ' (' + resourceSharedContent?.accessibility?.newWindow + ')'"
                    [href]="link.link"
                    >{{ link.name }} <i aria-hidden="true" class="fal fa-external-link font-xs"></i
                  ></a>
                </h3>
                <p>{{ link.description }}</p>
              </div>
            </div>
          </div>
        </section>
      </ng-container>
      <ng-container *ngSwitchCase="'frequently-asked-questions'">
        <section
          role="region"
          [attr.aria-label]="resourcePageContent.frequentlyAskedQuestions.headingSectionLabel"
          class="usa-prose">
          <h2>{{ resourceSharedContent?.frequentlyAskedQuestions?.title }}</h2>
          <p>{{ resourcePageContent.frequentlyAskedQuestions.description }}</p>
        </section>

        <section
        *ngIf="fragment | async as frag"
          role="region"
          class="display-flex tablet:flex-row-reverse flex-column gap-2"
          [attr.aria-label]="resourcePageContent.frequentlyAskedQuestions.listSectionLabel">
          <aside
            #navigation

            class="usa-in-page-nav bg-base-lightest margin-left-0 margin-top-0"
            data-scroll-offset="0"
            data-root-margin="-999999px 0px -999999px 0px"
            data-threshold="1">
            <nav aria-label="FAQ Categories:" class="usa-in-page-nav__nav bg-base-lightest">
              <h3 class="usa-in-page-nav__heading" tabindex="0">On this page</h3>
              <ul class="usa-in-page-nav__list border-base-light">
                <li
                  [attr.aria-current]="frag === link.fragment"
                  class="usa-in-page-nav__item usa-in-page-nav__item--primary"
                  *ngFor="let link of resourceSharedContent?.frequentlyAskedQuestions?.categories">
                  <a
                    [ngClass]="{ 'usa-current': frag === link.fragment }"
                    [routerLink]="['.']"
                    queryParamsHandling="merge"
                    [fragment]="link.fragment"
                    class="usa-in-page-nav__link"
                    >{{ link.name }}</a
                  >
                </li>
              </ul>
            </nav>
          </aside>


      

          <ng-container *ngFor="let category of resourceSharedContent?.frequentlyAskedQuestions?.categories">
            <div class="display-flex flex-column gap-2" *ngIf="frag === category?.fragment">
              <h3 class="margin-0">{{ category.name }}</h3>

              <div class="frequently-asked-questions-list">
                <ul class="usa-list display-flex flex-column gap-1 usa-list--unstyled">
                  <li
                    class="padding-2 bg-white radius-lg border-base-lighter display-flex flex-column gap-1 border-2px"
                    *ngFor="let faq of category.questions">
                    <h4 class="margin-0">{{ faq.question }}</h4>
                    <p class="usa-prose">{{ faq.answer }}</p>
                  </li>
                </ul>
              </div>
            </div>
          </ng-container>
        </section>
      </ng-container>
    </ng-container>
  </section>
</ng-container>
