<div *ngIf="show" class="background-overlay" (click)="toggleFilterPanel()"></div>
<div
  [hidden]="!show"
  #filterPanel
  class="report-filtering position-fixed overflow-auto top-0 right-0 height-viewport width-full maxw-mobile display-flex flex-column gap-05 z-200 bg-base-lightest border-left-1px border-base-lighter radius-left-lg shadow-2"
  role="dialog"
  aria-modal="true"
  cdkTrapFocus
  aria-labelledby="filterPanelTitle">
  <div class="display-flex margin-y-1 padding-x-2 flex-align-start gap-2 flex-justify">
    <h2 id="filterPanelTitle" class="font-lg text-semibold margin-x-0 margin-bottom-0 margin-top-1">{{ title }}</h2>
    <button
      type="button"
      class="usa-button usa-button--unstyled width-auto text-no-underline margin-left-auto"
      id="filterPanelCloseButton"
      (click)="toggleFilterPanel()">
      Close
      <i class="fas fa-times" aria-hidden="true"></i>
    </button>
  </div>
  <p class="padding-x-2">{{ description }}</p>

  <div
    class="display-flex flex-1 margin-top-2 padding-2 overflow-auto flex-column gap-3 bg-white border-top-05 border-accent-warm">
    <ng-content select="[body]"></ng-content>
  </div>

  <div class="display-flex flex-column margin-top-2 gap-2 padding-x-2 padding-bottom-2">
    <ng-content select="[footer]"></ng-content>
  </div>
</div>
