import { Component, Input } from '@angular/core';

@Component({
  selector: 'lib-adapt-expandable-list-item',
  templateUrl: './expandable-list-item.component.html',
  styleUrls: ['./expandable-list-item.component.scss'],
})
export class ExpandableListItemComponent {
  /**
   * A unique identifier for the expandable list item component.
   * The ID is generated by concatenating the string "expandable-list-item-" with a random number between 0 and 999,999.
   */

  @Input() headerTag: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' = 'h4';

  id = 'expandable-list-item-' + Math.floor(Math.random() * 1000000);
}
