<div class="adapt-form-field display-flex gap-1 flex-column">
  <ng-container *ngIf="!readOnly; else isReadOnly">
    <label [for]="id">{{ label }} <span *ngIf="isRequired" class="text-emergency">(Required)</span></label>
    <span *ngIf="hint" class="field-hint text-base">{{ hint }}</span>

    <ng-container [ngSwitch]="type">
      <ng-container *ngSwitchCase="'short'">
        <input
          [autocomplete]="autocomplete"
          (blur)="onBlur()"
          [(ngModel)]="value"
          [disabled]="disabled"
          (change)="onChange($any($event.target)?.value)"
          class="usa-input maxw-full"
          [id]="id"
          type="text" />
      </ng-container>

      <ng-container *ngSwitchCase="'number'">
        <input
          [autocomplete]="autocomplete"
          (blur)="onBlur()"
          [(ngModel)]="value"
          [disabled]="disabled"
          (change)="onChange($any($event.target)?.value)"
          class="usa-input maxw-full"
          [id]="id"
          type="number" />
      </ng-container>

      <ng-container *ngSwitchCase="'long'">
        <textarea
          [autocomplete]="autocomplete"
          (blur)="onBlur()"
          [disabled]="disabled"
          [(ngModel)]="value"
          (change)="onChange($any($event.target)?.value)"
          class="usa-textarea maxw-full"
          [id]="id"
          type="text"></textarea>
      </ng-container>

      <ng-container *ngSwitchCase="'password'">
        <input
          [autocomplete]="autocomplete"
          (blur)="onBlur()"
          [disabled]="disabled"
          [(ngModel)]="value"
          (change)="onChange($any($event.target)?.value)"
          class="usa-input maxw-full"
          [id]="id"
          type="password" />
      </ng-container>
    </ng-container>

    <div role="alert" *ngIf="parent?.invalid && (parent?.dirty || parent?.touched)" class="form-errors text-emergency">
      <ng-content select="[required]"></ng-content>
    </div>
  </ng-container>

  <ng-template #isReadOnly>
    <div class="adapt-form-field-read-only-preview display-flex gap-2 flex-column">
      <label [for]="id">{{ label }}</label>
      <span *ngIf="hint" class="field-hint text-base">{{ hint }}</span>

      <p tabindex="0" *ngIf="value || value.length" [id]="id" class="bg-white padding-1 border-1px">{{ value }}</p>
    </div>
  </ng-template>
</div>
