<section *ngIf="show" class="tab-view-container">
  <div class="tab-view-list">
    <ol class="tab-selector usa-list width-full usa-list--unstyled display-flex">
      <li
        tabindex="0"
        class="padding-2 flex-1 border-bottom-05 border-base-light"
        [ngClass]="{ ' border-primary': selectedTab.name === tab.name }"
        (keydown.enter)="selectTab(i)"
        (click)="selectTab(i)"
        *ngFor="let tab of tabs; let i = index">
        {{ tab.name }}
      </li>
    </ol>
  </div>
  <div *ngIf="selectedTab?.content" class="tab-content-container padding-2">
    <ng-template [ngTemplateOutlet]="selectedTab.content"></ng-template>
  </div>
</section>
