import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ContentService {
  //private _content = new ReplaySubject<any>();
  //public $content = this._content.asObservable();

  constructor(private http: HttpClient) // @Inject(CONTENT_LOCATION) public defaultContent: string,
  {
    // this.http.get(defaultContent).subscribe((result) => {
    //   this._content.next(result);
    // });
  }

  loadContent(defaultContentFilePath: string): Observable<any> {
    //console.log('Inside loadContent, defaultContentFilePath: ', defaultContentFilePath);
    return this.http.get(defaultContentFilePath);
  }
}
