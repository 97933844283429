import { Component, Input } from '@angular/core';

@Component({
  selector: 'branding-header',
  templateUrl: './branding-header.component.html',
  styleUrls: ['./branding-header.component.scss'],
})
export class BrandingHeaderComponent {
  @Input() name: string | null = 'User Name';
  @Input() role: string | null = 'User Role';
  @Input() organization: string | null = 'User Organization';
}
