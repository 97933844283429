<!--<adapt-session-reload-banner></adapt-session-reload-banner>-->
<branding-header></branding-header>

<section aria-label="Recent activities and common actions" class="padding-top-3 tablet:padding-top-5">
  <div class="grid-container padding-x-0 tablet:padding-x-5">
    <div class="display-flex margin-top-4 width-full padding-x-2 tablet:padding-x-0 flex-column">
      <div
        class="bg-white padding-2 tablet:padding-3 border-2px display-flex flex-column flex-1 border-base-lighter tablet:radius-lg">
        <div class="usa-prose">
          <h2>About Idaho's Infant Toddler Reports</h2>
          <p>
            Here is a list of common actions you can perform, making it easy to quickly access and manage your routine
            tasks.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>

<section aria-label="Frequently asked questions" class="padding-y-4 desktop:padding-y-6">
  <div *ngIf="contentText" class="grid-container padding-x-0 tablet:padding-x-5">
    <div class="display-flex width-full padding-x-2 tablet:padding-x-0 flex-column">
      <div
        class="questions-layout display-flex accessibility-layout flex-row gap-2 flex-align-start flex-justify flex-wrap margin-top-4">
        <div
          aria-label="Frequently asked questions list"
          role="region"
          *ngFor="let q of selFreqAskedQuestions | fieldLengthSort : 'answer'"
          class="bg-white padding-2 desktop:padding-3 gap-2 border-2px grid-col display-flex flex-column width-full border-base-lighter tablet:radius-lg">
          <div class="usa-prose">
            <h3>{{ q.question }}</h3>
            <p>{{ q.answer }}</p>
          </div>
          <lib-adapt-tag status="active">{{ q.categoryName }}</lib-adapt-tag>
        </div>
        <div class="home-faqs-view-more">
          <a [routerLink]="['resources']" [queryParams]="{'navigation': 'frequently-asked-questions'}" class="usa-button usa-button--outline">
            <i aria-hidden="true" class="fal fa-folder"></i>{{ contentText.shared.frequentlyAskedQuestions.button_text }}
          </a>
        </div>
      </div>
    </div>
  </div>
</section>

<section aria-label="Latest Reports">
  <div class="grid-container padding-x-0 display-flex flex-column gap-2 tablet:padding-x-5">
    <ng-container *ngIf="contentText?.pages?.home?.latestReports as reportContent">
      <div class="display-flex usa-prose flex-column flex-align-start accessibility-layout gap-2">
        <h3 class="margin-0">{{ reportContent.title }}</h3>
        <p class="measure-4">
          {{ reportContent.description }}
        </p>
      </div>

      <div class="home-reports-list display-flex flex-column gap-1">
        <lib-adapt-expandable-list-item *ngFor="let report of reports$ | async">
          <div visible-content class="display-flex gap-105 flex-column">
            <lib-adapt-h-element [ngClass]="'usa-prose margin-0'" level="h3">
              <a
                class="text-primary hover:primary-dark text-semibold text-no-underline cursor-pointer display-inline-flex width-auto"
                [routerLink]="['reports', report.slug]"
                >{{ report.name }}</a
              >
            </lib-adapt-h-element>

            <p class="text-normal font-sm line-height-5 text-ink">{{ report.template.description }}</p>

            <p class="text-normal font-sm line-height-4 text-ink">
              {{ report.updated | date }}
            </p>
          </div>

          <div action-content class="display-flex gap-2">
            <button   [routerLink]="['reports', report.slug]" class="usa-button">
              <i class="fal fa-eye" role="img" aria-hidden="true"></i> {{ reportContent.viewButton }}
            </button>
            <button class="usa-button usa-button--outline">
              <i class="fal fa-share" aria-hidden="true"></i> {{ reportContent.shareButton }}
            </button>
            <button class="usa-button usa-button--outline">
              <i class="fal fa-table" role="img" aria-hidden="true"></i>{{ reportContent.dataButton }}
            </button>
          </div>
        </lib-adapt-expandable-list-item>
      </div>

      <div class="home-reports-view-more">
        <a [routerLink]="['reports']" class="usa-button usa-button--outline">
          <i aria-hidden="true" class="fal fa-folder"></i>{{ reportContent.button_text }}
        </a>
      </div>
    </ng-container>
  </div>
</section>
