<section
  aria-label="Welcome banner"
  class="hero-container shadow-1 display-flex flex-align-center padding-x-2 tablet:padding-x-4 gap-4 border-top border-bottom border-base-lighter">
  <lib-adapt-inline-svg
    alt="ADAPT logo"
    src="assets/shared/logos/idhw-logo.svg"
    svgClass="display-none desktop:display-flex width-mobile"></lib-adapt-inline-svg>
  <div class="welcome-user-info width-full display-flex flex-column tablet:flex-row gap-2 padding-y-8">
    <div class="user-info-heading-name-role display-flex gap-1 flex-column">
      <span>Infant Toddler Program</span>
      <h1 class="font-3xl font-weight-normal margin-0">Public Reporting</h1>
      <h1 class="font-3xl font-weight-normal margin-0">Ages 0-3 (Part C)</h1>
    </div>
  </div>
</section>
